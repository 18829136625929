import React from "react";

import { HasuraConSEO } from "../../../components/hasuracon2021/HasuraConSEO";
import Layout from "../../../components/hasuracon2021/Layout";
import CocTopBanner from "../../../components/hasuracon2021/coctopbanner";

const canonicalUrl = "https://hasura.io/events/hasura-con-2021/code-of-conduct/";

const HasuraCon = props => (
  <Layout location={props.location}>
    <HasuraConSEO canonicalLink={canonicalUrl} />
    <CocTopBanner />
  </Layout>
);

export default HasuraCon;
